<template>
	<b-container fluid>
		<b-row>
			<b-col md="12">
				<iq-card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Lista de Pacientes</h4>
					</template>
					<template v-slot:headerAction>
						<b-button
							v-user-can="'crear pacientes'"
							variant="primary"
							@click="showModal('add', clearForm)"
						>
							Agregar
						</b-button>
					</template>
					<template v-slot:body>
						<DataTable
							v-if="patients"
							:data="patients"
							:columns="columns"
							:fields-search="[
								'first_last_name',
								'second_last_name',
								'name',
								'email',
								'document_number',
								'age',
							]"
						>
							<template #patient_type>
								<b-form-group
									class="col-md-2"
									label-for="TIPO DE PACIENTE"
									label="TIPO DE PACIENTE:"
								>
									<b-form-select
										@change="handleSearchPatient"
										v-model="type_patient"
										plain
										:options='["Todos", "Medico", "Fisioterapeutico"]'
										id="patient_type"
									>
									</b-form-select>
								</b-form-group>
							</template>
							<template #rows="{ row, rowIndex }">
								<tr>
									<th scope="row">{{ ++rowIndex }}</th>
									<td>
										{{ row.first_last_name }}
										{{ row.second_last_name }}, {{ row.name }} <br />
										<small><b>Edad:</b> {{ row.age }} años</small> |
										<small><b>Género:</b> {{ row.gender }}</small>
									</td>
									<td>
										{{ row.document_number }} <br />
										<small><b>Tipo:</b> {{ row.document_type }}</small>
									</td>
									<td>{{ row.phone }}</td>
									<td>{{ row.email }}</td>
									<td>
										<b-button-group>
											<b-button
												v-user-can="'leer pacientes'"
												variant="info"
												size="sm"
												@click="goToHistory(row.id)"
												v-b-tooltip.hover
												title="Historial Clínico"
											>
												<i class="ri-survey-line m-0"></i>
											</b-button>
											<b-button
												v-user-can="'leer pacientes'"
												variant="success"
												size="sm"
												@click="goToPhysiotherapeutic(row.id)"
												v-b-tooltip.hover
												title="Historial Fisioterapeutico"
											>
												<i class="ri-survey-line m-0"></i>
											</b-button>
											<b-button
												v-user-can="'leer pacientes'"
												variant="success"
												size="sm"
												@click="goToEvolutionNotes(row.id)"
												v-b-tooltip.hover
												title="Notas de Evolucion"
											>
												<i class="ri-survey-line m-0"></i>
											</b-button>
											<b-button
												v-user-can="'leer pacientes'"
												variant="secondary"
												size="sm"
												@click="goToEdit(row.id)"
												v-b-tooltip.hover
												title="Detalle Paciente"
											>
												<i class="ri-file-list-line m-0"></i>
											</b-button>
											<b-button
												v-user-can="'actualizar pacientes'"
												variant="warning"
												size="sm"
												@click="showModal('edit', row)"
												v-b-tooltip.hover
												title="Editar Paciente"
											>
												<i class="ri-ball-pen-fill m-0"></i>
											</b-button>
											<b-button
												v-user-can="'eliminar pacientes'"
												variant="danger"
												size="sm"
												@click="destroy(row.id)"
												v-b-tooltip.hover
												title="Eliminar Paciente"
											>
												<i class="ri-delete-bin-line m-0"></i>
											</b-button>
										</b-button-group>
									</td>
								</tr>
							</template>
						</DataTable>
					</template>
				</iq-card>
			</b-col>
		</b-row>

		<ModalForm @getData="getData" :type="type" :data="data" />
	</b-container>
</template>

<script>
import { Toast } from "./../../../mixins/toast";
import { xray } from "../../../config/pluginInit";
import api from "./../../../services/api";
import DataTable from "./../../../components/DataTable.vue";
import ModalForm from "./ModalForm.vue";
import Swal from "sweetalert2";

export default {
	components: {
		ModalForm,
		DataTable,
	},
	mounted() {
		xray.index();
		this.getData();
	},
	methods: {
		async getData() {
			try {
				const { data } = await api.get("/patients");
				this.patients = data.data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		showModal(type, data) {
			this.type = type;
			this.data = data;
			this.$bvModal.show("modal-form");
		},
		destroy(id) {
			Swal.fire({
				icon: "warning",
				title: "Seguro de eliminar",
				showDenyButton: false,
				showCancelButton: true,
				confirmButtonText: "Si",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.isConfirmed) {
					api
						.delete(`/patients/${id}`)
						.then((res) => {
							if (res.status === 200) {
								Toast.fire({
									icon: "success",
									title: "Eliminado correctamente",
								});
								this.getData();
							}
						})
						.catch((error) => {
							Toast.fire({
								icon: "error",
								title: error.message,
							});
						});
				}
			});
		},
		goToEdit(userID) {
			localStorage.setItem("userEdit", userID);
			this.$router.push({ name: "patient.detail" });
		},
		goToHistory(userID) {
			localStorage.setItem("userEdit", userID);
			this.$router.push({ name: "patient.medical.history", params: { id: userID } });
		},
		goToPhysiotherapeutic(userID) {
			localStorage.setItem("userEdit", userID);
			this.$router.push({ name: "patient.physiotherapeutic.history", params: { id: userID } });
		},
		goToEvolutionNotes(userID) {
			localStorage.setItem("userEdit", userID);
			this.$router.push({ name: "patient.evolution.notes", params: { id: userID } });
		},
		async handleSearchPatient(e) {
			try {
				const { data } = await api.get(`/patients/type/${this.type_patient}`);
				this.patients = data.data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		}
	},
	data() {
		return {
			data: {},
			type: "add",
			patients: [],
			columns: [
				{
					name: "Nombre",
					field: "first_last_name",
					sort: "",
				},
				{
					name: "Documento",
					field: "document_number",
					sort: "",
				},
				{
					name: "Teléfono",
					field: "phone",
					sort: "",
				},
				{
					name: "Correo Electrónico",
					field: "email",
					sort: "",
				},
				{
					name: "Acciones",
					field: "actions",
					sort: "",
				},
			],
			clearForm: {
				first_last_name: "",
				second_last_name: "",
				name: "",
				document_type: "",
				document_number: "",
				email: "",
				password: "",
				civil_status: "",
				birthdate: "",
				gender: "",
				province: "",
				city: "",
				phone: "",
				address: "",
			},
			type_patient: ""
		};
	},
};
</script>
