<template>
  <b-modal id="modal-form" size="xl" :title="type === 'add' ? 'Agregar Paciente' : 'Editar Paciente'" no-close-on-backdrop
    hide-footer>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-form-group class="col-md-4" label="Primer Apellido:" label-for="first_last_name">
            <ValidationProvider name="Primer Apellido" rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.first_last_name" type="text" placeholder="Primer Apellido"
                :class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-4" label="Segundo Apelllido:" label-for="second_last_name">
            <ValidationProvider name="Segundo Apelllido" rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.second_last_name" type="text" placeholder="Segundo Apelllido"
                :class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-4" label="Nombres:" label-for="name">
            <ValidationProvider name="Nombres" rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.name" type="text" placeholder="Nombres"
                :class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-6" label-for="document_type" label="Tipo de Documento:">
            <b-form-select v-model="form.document_type" plain :options="['CEDULA', 'PASAPORTE']" id="document_type">
            </b-form-select>
          </b-form-group>
          <b-form-group class="col-md-6" label="Número de Documento:" label-for="document_number">
            <ValidationProvider name="Número de Documento" rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.document_number" type="text" placeholder="Número de Documento"
                :class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-6" label="Correo Electrónico:" label-for="email">
            <ValidationProvider name="Correo Electrónico" rules="required|email" v-slot="{ errors }">
              <b-form-input :disabled="type === 'edit'" v-model="form.email" type="email" placeholder="Correo Electrónico"
                :class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-6" label="Contraseña:" label-for="password">
            <ValidationProvider name="Contraseña" rules="" v-slot="{ errors }">
              <b-form-input v-model="form.password" type="password" placeholder="Contraseña"
                :class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-4" label-for="civil_status" label="Estado Civil:">
            <b-form-select v-model="form.civil_status" plain
              :options="['SOLTERO', 'CASADO', 'VIUDO', 'DIVORCIADO', 'UNION LIBRE']" id="civil_status">
            </b-form-select>
          </b-form-group>
          <b-form-group class="col-md-4" label-for="civil_status" label="Tipo de Paciente:">
            <b-form-select v-model="form.patient_type" plain
              :options="['Medico', 'Fisioterapeutico']" id="type_patient">
            </b-form-select>
          </b-form-group>

          <b-form-group class="col-md-4" label="Género:" label-class="d-block">
            <b-form-radio inline v-model="form.gender" value="MASCULINO">
              MASCULINO
            </b-form-radio>
            <b-form-radio inline v-model="form.gender" value="FEMENINO">
              FEMENINO
            </b-form-radio>
          </b-form-group>

          <b-form-group class="col-md-4" label="Fecha de Nacimiento:" label-for="birthdate">
            <ValidationProvider name="Fecha de Nacimiento" rules="required" v-slot="{ errors }">
              <input class="form-control" type="date" v-model="form.birthdate"
                :class="errors.length > 0 ? ' is-invalid' : ''" />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-6" label="Teléfono:" label-for="phone">
            <ValidationProvider name="Teléfono" rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.phone" type="text" placeholder="Teléfono"
                :class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-6" label="Dirección:" label-for="address">
            <ValidationProvider name="Dirección" rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.address" type="text" placeholder="Dirección"
                :class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-6" label="Ocupación:" label-for="Ocupación">
            <ValidationProvider name="Ocupación" rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.occupation" type="text" placeholder="Ocupación"
                :class="errors.length > 0 ? ' is-invalid' : ''"></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-6" label-for="province" label="Provincia:">
            <b-form-select v-model="selectedProvince" plain :options="provinces" id="province" @change="getCantones"
              value-field="id" text-field="name">
            </b-form-select>
          </b-form-group>
          <b-form-group class="col-md-6" label-for="city" label="Ciudad:">
            <b-form-select v-model="form.city" plain :options="cantones" id="city" value-field="canton"
              text-field="canton">
            </b-form-select>
          </b-form-group>
          <div class="col-md-12 w-100">
            <b-button type="submit" variant="primary" class="float-right">
              {{ type === "add" ? "Guardar" : "Actualizar" }}
            </b-button>
          </div>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { Toast } from './../../../mixins/toast'
import api from './../../../services/api'
import Provinces from '../../../FackApi/json/Provinces.json'

export default {
  props: {
    type: {
      type: String,
      required: true,
      default: 'add'
    },
    data: {
      type: Object,
      required: false
    }
  },
  mounted () {
    this.getProvinces()
  },
  data () {
    return {
      provinces: [],
      cantones: [],
      selectedProvince: '',
      form: {}
    }
  },
  watch: {
    data: function (newValue, oldValue) {
      this.form = newValue

      if (this.type === 'edit') {
        const searchValue = this.provinces.find(
          (item) => item.name.toLowerCase() === newValue.province.toLowerCase()
        )

        if (searchValue) {
          this.form.province = searchValue.name
          this.selectedProvince = searchValue.id
          this.getCantones()
        }

        this.form.city = newValue.city
      } else {
        this.selectedProvince = ''
      }
    }
  },
  methods: {
    onSubmit () {
      if (this.type === 'add') {
        this.saveData()
      } else {
        this.updateData()
      }
    },
    async saveData () {
      try {
        await api.post('/patients/register', this.form)
        this.$bvModal.hide('modal-form')
        this.$emit('getData')
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    async updateData () {
      api
        .put(`/patients/${this.data.id}`, this.form)
        .then(() => {
          Toast.fire({
            icon: 'success',
            title: 'Actualizado Correctamente'
          })
          this.$bvModal.hide('modal-form')
          this.$emit('getData')
        })
        .catch(() => {
          Toast.fire({
            icon: 'error',
            title: 'Error'
          })
        })
    },
    getProvinces () {
      for (let key in Provinces) {
        if (Provinces.hasOwnProperty(key)) {
          if (Provinces[key].provincia) {
            const provincia = Provinces[key].provincia
            this.provinces.push({
              id: key,
              name: provincia
            })
          }
        }
      }
    },
    getCantones () {
      const data = Provinces[this.selectedProvince]
      this.form.province = data.provincia
      this.cantones = Object.values(data.cantones)
    }
  }
}
</script>
